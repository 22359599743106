import type { AppProps } from 'next/app';
import { Fragment } from 'react';
import { HydrationProvider } from 'react-hydration-provider';
import { AuthProvider } from '../lib/context/auth/AuthContext';
import ContextWrapper from '../lib/context/ContextWrapper';
import './globals.css';
import { NextPageWithLayout } from './page';

interface AppPropsWithLayout extends AppProps {
  Component: NextPageWithLayout;
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <HydrationProvider>
      <Fragment>
        <ContextWrapper>
          <AuthProvider>{getLayout(<Component {...pageProps} />)}</AuthProvider>
        </ContextWrapper>
      </Fragment>
    </HydrationProvider>
  );
}

export default MyApp;
